import React, { useEffect, useState } from 'react';
import Button from '../../../../common/Button';
import { CrossIcon1, LocationIcon, MenuIcon1, ProfileIcon2, ArrowDownIcon, DownArrorIcon, DisableClosedIcon } from '../../../../common/Icons';
import useGetData from '../../../../Hook/useGetData';
import usePost from '../../../../Hook/usePost';
import { ToastMessage } from '../../../../common/Config';
import { convertDate, formatTime } from '../../../../Hook/validation';
import { BankerCalendarViewStyle1, BankerCalendarViewStyle10, BankerCalendarViewStyle11, BankerCalendarViewStyle12, BankerCalendarViewStyle13, BankerCalendarViewStyle14, BankerCalendarViewStyle15, BankerCalendarViewStyle16, BankerCalendarViewStyle17, BankerCalendarViewStyle18, BankerCalendarViewStyle19, BankerCalendarViewStyle2, BankerCalendarViewStyle20, BankerCalendarViewStyle3, BankerCalendarViewStyle4, BankerCalendarViewStyle5, BankerCalendarViewStyle6, BankerCalendarViewStyle7, BankerCalendarViewStyle8, BankerCalendarViewStyle9 } from './CalendarStyles';

const BankerEventView = ({ isOpen, onClose,onSave,eventInfo,setIsModalOpen,setEventInfo  }) => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const [showRSVPOptions, setShowRSVPOptions] = useState(false);
  const [showResponseOptions, setShowResponseOptions] = useState(false);
  const [reason,setReason]=useState("");
  const [choice,setChoice]=useState("");
  const { data: getBankerList } = useGetData(`hiring/get-company`,token);
  const { data: CancelEventData,loading:CancelEventLoading, 
    error: CancelEventError, postData: postCancelEventData } = usePost(`hiring/update-cancellation`);
    const bankerName=getBankerList?.data?.filter((item)=>item?.id===eventInfo?.company)?.[0]?.name;
 

    const toggleRSVPOptions = () => {
      let Condition=!showRSVPOptions;
      setShowRSVPOptions(!showRSVPOptions);
      if(Condition===false && showResponseOptions===true){
        setShowResponseOptions(false)
      }
    };

  const toggleResponseOptions = () => {
    setShowResponseOptions(!showResponseOptions);
  };
  const handleEdit=()=>{
    setEventInfo({...eventInfo,edit:true});
    setIsModalOpen(true)
  }
  const handleChoice=(choice)=>{
    setChoice(choice)
    const obj={
      "event_id":eventInfo?.id || 37,
      "reason":reason,
      "reason_choice":choice
  }
  postCancelEventData(obj,token)

  }
useEffect(()=>{
  if(CancelEventData){
    if(choice==='accept'){
      ToastMessage("success","Event Accepted Successfully")
    }
    if(choice==='decline'){
      ToastMessage("success","Event Rejected Successfully")
    }
    if(choice==='tentative'){
      ToastMessage("success","Event Tentative Successfully")
    }
    onClose()
    // onSave();
  }
  if(CancelEventError){
    ToastMessage("error",CancelEventError?.response?.data?.message || "Something Went Wrong")
  }
},[CancelEventData,CancelEventError])



if (!isOpen) return null;
  return (
    <div className={BankerCalendarViewStyle1}>
      <div className={BankerCalendarViewStyle2}>
        <div className={BankerCalendarViewStyle3}>
          <div>
          <h6 className="h6-text">{eventInfo?.title || "Not Available"}</h6>
            <p className={BankerCalendarViewStyle4}>
             
              {convertDate(eventInfo?.event_date) || "00:00"} - {formatTime(eventInfo?.start_time) || "00:00"} to {formatTime(eventInfo?.end_time) || "00:00"}
          </p>
          </div>
          <Button
            type="button"
            onClick={()=>{
              setEventInfo({
                  title: '',
                  is_virtual: true,
                  preferred_date: '',
                  banker: '',
                  email: '',
                  preferred_start_time: '',
                  preferred_end_time: '',
                  description: '',
                  priority: 1,
                })
              onClose()}}
            className={BankerCalendarViewStyle5}
          >
            <CrossIcon1 />
          </Button>
        </div>

        <div className={BankerCalendarViewStyle6}>
          <div className={BankerCalendarViewStyle7}>
          {eventInfo?.meeting_link ?   <a href={eventInfo?.meeting_link} aria-disabled="true" target="_blank" rel="noreferrer">
                <Button className={BankerCalendarViewStyle8}>
                    Join
                </Button>
                </a>:
                
                <Button className={BankerCalendarViewStyle9}>
                        Join
                  </Button>
                }
                {eventInfo?.banker===eventInfo?.created_by ? 
                      <Button className={BankerCalendarViewStyle10} onClick={handleEdit}>
                      Edit
                </Button>:
   <div className="relative">
    <Button 
     className={BankerCalendarViewStyle11}
     onClick={toggleRSVPOptions}
     disabled={eventInfo?.status !==0}
   >
 {eventInfo?.status !== 0 && (
  eventInfo?.status === 1 ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px" className='mr-4'>
      <path fill="none" stroke="#000000" stroke-width="2" d="M20 6L9 17l-5-5"/>
    </svg>
  ) : eventInfo?.status === 2 ? (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
      <path fill="none" stroke="#000000" stroke-width="2" d="M9 9c0-1.656 1.344-3 3-3s3 1.344 3 3c0 1.47-1.053 2.412-2.412 3.005C11.158 12.823 11 13.194 11 14m0 4h2"/>
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
      <path fill="none" stroke="#000000" stroke-width="2" d="M18 6L6 18M6 6l12 12"/>
    </svg>
  )
)}

     RSVP
     <DownArrorIcon className="ml-2" />
   </Button>
   {showRSVPOptions && (
     <div className={BankerCalendarViewStyle12}>
       <Button
         className={BankerCalendarViewStyle13}
         onClick={() => {
          //  setShowRSVPOptions(false);
           toggleResponseOptions();
           setReason("respond_to_occurrence")
         }}
       >
         Respond to occurrence
       </Button>
       <Button
         className={BankerCalendarViewStyle14}
         onClick={() => {
          //  setShowRSVPOptions(false);
           toggleResponseOptions();
           setReason("respond_to_series")
         }}
       >
         Respond to series
       </Button>
     </div>
   )}
 </div>
              }
         
      
          </div>

          {showResponseOptions && (
            <div className={BankerCalendarViewStyle15}>
              <div className={BankerCalendarViewStyle16}>
                <Button
                  className={BankerCalendarViewStyle17}
                  onClick={() => {
                    handleChoice("accept")
                    setShowResponseOptions(false);
                    setShowRSVPOptions(false);
                    // Handle "Accept"
                  }}
                >
                  Accept
                </Button>
                <Button
                  className={BankerCalendarViewStyle17}
                  onClick={() => {
                    handleChoice("tentative")
                    setShowResponseOptions(false);
                    setShowRSVPOptions(false);
                    // Handle "Tentative"
                  }}
                >
                  Tentative
                </Button>
                <Button
                  className={BankerCalendarViewStyle17}
                  onClick={() => {
                    handleChoice("decline")
                    setShowResponseOptions(false);
                    setShowRSVPOptions(false);
                    // Handle "Decline"
                  }}
                >
                  Decline
                </Button>
              </div>
            </div>
          )}

<div className={BankerCalendarViewStyle18}>
                <LocationIcon />
                <p className={BankerCalendarViewStyle19}>
                  {eventInfo?.consultancy_mode ==1 ? "Virtual":eventInfo?.consultancy_mode ==2 ? "In-Person": "Both"}
                  {eventInfo?.consultancy_mode !==1 && `(${eventInfo?.location || "Not Available"})`}</p>
            </div>

            <div className={BankerCalendarViewStyle18}>
                <ProfileIcon2 />
                <p className={BankerCalendarViewStyle19}>{bankerName || "Not Available"}</p>
            </div>

            <div className={BankerCalendarViewStyle18}>
                <MenuIcon1/>
                <p className={BankerCalendarViewStyle20}>
                  {eventInfo?.description || "Not Available"}
                </p>
            </div>
        </div>
      </div>
    </div>    
  );
};

export default BankerEventView;
