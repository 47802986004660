// BankerProject.jsx

import React, { useState, useEffect } from 'react';
import Navbar from '../../../../common/Navbar';
import BankerSideNavbar from '../../../../common/BankerSideNavbar';
import { JobListingCountStyle, JobListingMenuBarDivAvticeTab } from '../../company/job/DashboardJobStyle';
import { EyeIcon2, IButtonIcon, LeftArrowIcon, ManageMandateIcon, RightArrowIcon } from '../../../../common/Icons';
import InputField from '../../../../common/InputField';
import BankerProjectComp from './BankerProjectComp';
import { useNavigate } from 'react-router-dom';
import ToolTip from '../../../../common/ToolTip';
import { BMM_MainDiv7, BP_MainDiv, BP_MainDiv1, BP_MainDiv11, BP_MainDiv12, BP_MainDiv13, BP_MainDiv14, BP_MainDiv15, BP_MainDiv16, BP_MainDiv2, BP_MainDiv3, BP_MainDiv4, BP_MainDiv5, BP_MainDiv6, BP_MainDiv7, BP_MainDiv8, BP_MainDiv9 } from './BankerProjectStyle';
import { FlexJustCenter } from '../../company/project/CompanyProjectStyle';
import { flexjustifybt } from '../../../../common/StyleCommon';
import useGetData from '../../../../Hook/useGetData';
import MyCalendar from '../../../../common/WeeklyCalendar';
import WeeklyCalendar from '../../../../common/WeeklyCalendar';
import { addDays, endOfWeek, format, isSameDay, startOfWeek } from 'date-fns';
import { formatDateBanker, formatTime } from '../../../../Hook/validation';

const BankerProject = () => {
  const [activeTab, setActiveTab] = useState("All projects");
  const [list, setList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentWeekStart, setCurrentWeekStart] = useState(startOfWeek(new Date(), { weekStartsOn: 1 })); // Monday as the start of the week
  const token = JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: getProjectData} = useGetData('projects/banker', token);
  const { data: eventDataGet} = useGetData('hiring/get-event-banker', token);
  const { data: getCompanyData} = useGetData('hiring/get-company', token);
    const { data: getData,loading: loadingPayment, error: errorPayment,refetch:fetchData } = useGetData(`onboarding/get-payment-manadate`, token);
  const eventData = eventDataGet?.data?.map((item)=>{
    return {
      ...item,
      date:item?.event_date,
      time:`${formatTime(item?.start_time)}-${formatTime(item.end_time)}`,
      client:getCompanyData?.data?.filter((items)=>items?.id===item?.company)[0]?.name,
      color: item.consultancy_mode === 1 ? 'bg-purple-100 text-purple-700' : 'bg-green-100 text-green-700',
      location:item?.meeting_link ||  "Not available",
    }
  
  }) ||[];
  const [events,setEvents]=useState(eventData);

  const ToolTipColer =[
    {
      id:1,
      color:"bg-[#1EB469]",
      status:"Completed"
    },
    {
      id:2,
      color:"bg-[#EA9515]",
      status:"In Approval"
    },
    {
      id:3,
      color:"bg-[#FDD33E]",
      status:"Ongoing"
    },
    
    {
      id:4,
      color:"bg-[#FF0059]",
      status:"Declined"
    } ,
    {
      id:5,
      color:"bg-[#D8D9E0]",
      status:"Not Started"
    }
  ]
  const getProjectList = {
    data:getProjectData?.data
  };
  const currentWeekEnd = endOfWeek(currentWeekStart, { weekStartsOn: 1 });
  const daysOfWeek = Array.from({ length: 7 }, (_, i) => addDays(currentWeekStart, i));

  const handleWeekChange = (direction) => {
    const newWeekStart = addDays(currentWeekStart, direction * 7);
    setCurrentWeekStart(newWeekStart);
    
    const filteredEvents = eventData.filter(event => {
      const eventDate = event?.date;
      return eventDate >= format(newWeekStart,'yyyy-MM-dd') && eventDate <= format(currentWeekEnd,'yyyy-MM-dd');
    });
   
  setEvents(filteredEvents)

  };

  
  const filteredEvents = events.filter(event => {
    const eventDate = new Date(event?.event_date);
    return eventDate >= currentWeekStart && eventDate <= currentWeekEnd;
  });
  const handleDateChange=(date)=>{

  const filteredEvents = eventData.filter(event => {
    const eventDate =date;
    return event?.event_date==eventDate
  });
  setEvents(filteredEvents)
  }
  useEffect(() => {
    setList(getProjectData?.data);
  }, [getProjectData?.data]);

  const handleTabClick = (tab) => {
    setActiveTab(tab?.name);
    if (tab?.id === 1) {
      const data=getProjectList?.data?.filter((item) => 
      item?.milestones?.some(milestone => milestone.status === 1)
    ) || []
      setList(data);
    }
    if (tab?.id === 2) {
      const data=getProjectList?.data?.filter((item) => 
      item?.milestones?.some(milestone => milestone.status === 4)
    ) || []
      setList(data);
    }
    if (tab?.id === 3) {
      setList(getProjectList.data);
    }
  };

  const mainTag = [
    {
      id: 1,
      name: "Ongoing",
      length: getProjectList?.data?.filter((item) => 
        item?.milestones?.some(milestone => milestone.status === 1)
      ).length || 0,
    },
    {
      id: 2,
      name: "Completed",
      length: getProjectList?.data?.filter((item) => 
      item?.milestones?.some(milestone => milestone.status === 4)
    ).length || 0,
    },
    {
      id: 3,
      name: "All projects",
      length: getProjectList?.data?.length || 0,
    },
  ];

  const filteredList = list?.filter((project) =>
    project.project_name?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
    project.subTitle?.toLowerCase().includes(searchQuery.toLowerCase())
  );
 

  const navigate = useNavigate();
  const StatusToolTip = () => (
    <div className='w-[150px]  '>
        {ToolTipColer?.map((item , index)=>{
          return <div className={BP_MainDiv}>
          <div className={`${BP_MainDiv1} ${item?.color}`}></div>
          <div className='body-text'>{item?.status}</div>
          </div>
        })}
      
    </div>
  )
  useEffect(()=>{
    sessionStorage.setItem("reloadCount",5);
  },[])
  return (
    <div>
      <Navbar hemBurgerIcon={true} BankerNavbar={true} ProfileAndNotification={true} />

      <div className='flex w-full'>
        <div className='w-0 lg:w-[7%]'>
          <BankerSideNavbar />
        </div>
        <div className={BP_MainDiv2}>
          <div className={BP_MainDiv3}>
            <div className='px-2'>
              <div className={BP_MainDiv5}>
                {mainTag.map((item, index) => (
                  <div key={index}>
                    <div
                      className={` ${JobListingMenuBarDivAvticeTab} ${activeTab === item?.name ? "font-bold" : ""}`}
                      onClick={() => handleTabClick(item)}
                    >
                      <div>{item?.name}</div>
                      <div className={JobListingCountStyle}>
                        {item?.length}
                      </div>
                    </div>
                    <div
                      className={`${BP_MainDiv6} ${activeTab === item?.name ? "greenbg" : "bg-transparent"}`}
                    ></div>
                  </div>
                ))}
              </div>
            </div>
            <div className={BP_MainDiv7}></div>
            <div className='lg:pl-6'>
              <div className='w-full lg:w-1/2 px-2 lg:px-0 my-8'>
                <InputField SearchIcon={true} placeholder='Search' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
              </div>

              <div className='hidden md:block'>
              <div className='flex justify-between my-3 w-full'>
                <div className='flex text-start md:w-[73%]'>
                  <div className='w-[35%]'>
                    <p className='notification-span text-[#8B8D98]'>Project Name</p>
                  </div>
                  <div className='w-[65%]'>
                    <p className='flex items-center gap-2 notification-span text-[#8B8D98]'>
                      Milestones Status <ToolTip message={<StatusToolTip/>}>
                          <IButtonIcon />
                        </ToolTip> 
                    </p>
                  </div>
                </div>
                <div className='grid grid-cols-2 w-[30%] justify-between'>
                  <div>
                    <p className='notification-span text-[#8B8D98]'>Project Due Date</p>
                  </div>
                  <div className='flex justify-cente'>
                    <p className='notification-span text-[#8B8D98]'>View Details</p>
                  </div>
                </div>
              </div>
              </div>
              <div className='px-2'>
              {filteredList?.filter((item)=>item?.payment_success ===true)?.map((project) => (
                <BankerProjectComp key={project.id} project={project} />
              ))}
              </div>
            </div>
          </div>

          <div className='w-full mt-8 lg:mt-0 lg:w-[25%]'>
            <div className={BP_MainDiv13}>
              <div className={flexjustifybt}>
              <div>
                <h6 className={BP_MainDiv15}>Mandates</h6>
              </div>
              <div>
                <h6 className='h6-text'> {getData?.consume_plan_manadate || 0} / {getData?.current_plan_manadate || 0}</h6>
               
              </div>
              </div>

              <div onClick={()=>navigate("/dashboard/banker/project/manage-mandate")} className='flex items-center gap-1 mt-4 cursor-pointer'>
                <ManageMandateIcon />
                <p className={BP_MainDiv16}>Manage Mandates</p>
              </div>
            </div>
            <div className={BP_MainDiv14}>
            <div className="max-w-lg mx-auto bg-white rounded-xl shadow-md overflow-hidden">
      <div className="p-4">
        {/* <h2 className="text-2xl font-bold mb-4">Upcoming Events</h2> */}
        <h6 className={BP_MainDiv15}>Upcoming Events</h6>
        {/* <WeeklyCalendar /> */}
        <div className='flex items-center justify-between mt-4'>
        <div className="text-[#2C2C2C] mb-4 body-text">{formatDateBanker(new Date())}</div>
        <div className="flex space-x-4 mb-4">
        <button className="focus:outline-none" onClick={() => handleWeekChange(-1)}>
                          <LeftArrowIcon />
                        </button>
                        <button className="focus:outline-none" onClick={() => handleWeekChange(1)}>
                          <RightArrowIcon />
                        </button>
        </div>
        </div>
        <div className="flex justify-between text-sm text-gray-500 mb-2">
                      {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day, index) => (
                        <div key={index} className={`${isSameDay(daysOfWeek[index], new Date()) ? "text-red-500 font-bold" : ""}`} >
                          {day}
                        </div>
                      ))}
                    </div>
                    <div className="flex justify-between text-sm text-gray-900 font-semibold mb-4">
                      {daysOfWeek.map((date, index) => (
                        <div key={index} className={`${isSameDay(date, new Date()) ? "text-red-500 font-bold" : ""}`} role='button' onClick={() => handleDateChange(format(new Date(date), 'yyyy-MM-dd'))}>
                          {format(date, 'd')}
                        </div>
                      ))}
                    </div>
                    {events.map((event, index) => (
                      <div key={index} className="mb-4">
                        <div className='flex items-center justify-between'>
                          <div className="text-sm  text-gray-600 mb-1">{event.time}</div>
                          <div className='border w-[65%]'></div>
                        </div>
                        <div className={`p-2 text-start body-text mt-3 rounded ${event.color}`}>
                          <div className="font-bold mb-1">Event Name</div>
                          <div className="font-bold mb-1">{event?.consultancy_mode===1 ? "Virtual" :"In person"}</div>
                          <div className="font-bold mb-1">{event.title}</div>
                          <div className="text-sm">{event.type}</div>
                          <div className="text-sm">{event?.consultancy_mode===1 ? event?.meeting_link:event.location}</div>
                          <div className="text-sm">{event.client}</div>
                        </div>
                      </div>
                    ))}
      </div>
    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankerProject;
