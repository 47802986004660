import React, { useState, useRef } from 'react';
import Navbar from '../../../../common/Navbar';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CompanyAddEvent from './CompanyAddEvent';
import SideNavbar from '../../../../common/SideNavbar';
import CompanyEventView from './CompanyEventView';
import CompanyCanlenderComp from './CompanyCanlenderComp';
import useGetData from '../../../../Hook/useGetData';
import { formatTime } from '../../../../Hook/validation';
const CompanyCalendar = () => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: getEventsData, loading: loadingEvent, error: errorEvent } = useGetData(`hiring/company-important-event`,token);
  const [activeTab, setActiveTab] = useState(true);
  const [events, setEvents] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dateInfo, setDateInfo] = useState(null);
  const [grid, setGrid] = useState('timeGridWeek');
  const calendarRef = useRef(null);
  const [value, onChange] = useState(new Date());
  const [isEventViewModalOpen, setisEventViewModalOpen] = useState(false);
  const [eventInfo, setEventInfo] = useState({
    "id": 0,
    "title": "",
    "location": "",
    "meeting_link": null,
    "event_date": "",
    "start_time": "",
    "end_time": "",
    "is_repeat": false,
    "repeat_interval": null,
    "repeat_unit": null,
    "repeat_start_date": null,
    "repeat_end_date": null,
    "description": "",
    "created_at": "",
    "updated_at": "",
    "banker": 0,
    "company": 0,
    "consultancy_mode": 0,
    "priority": 1
})
const handleDateClick = (info) => {
    setDateInfo(info);
    setIsModalOpen(true);
  };

  const handleAddEventClick = () => {
    setDateInfo(null);
    setIsModalOpen(true);
    setEventInfo({
      "id": 0,
      "title": "",
      "location": "",
      "meeting_link": null,
      "event_date": "",
      "start_time": "",
      "end_time": "",
      "is_repeat": false,
      "repeat_interval": null,
      "repeat_unit": null,
      "repeat_start_date": null,
      "repeat_end_date": null,
      "description": "",
      "created_at": "",
      "updated_at": "",
      "banker": 0,
      "company": 0,
      "consultancy_mode": 0,
      "priority": 1
  })
  };
  const EventItem = ({ color, title, time }) => {
    return (
      <div className='flex justify-between items-center my-2'>
        <div className='flex items-center'>
          <span className={`inline-block w-2.5 h-2.5 rounded-full ${color}`}></span>
          <p className={`body-text ${color}`}>{title}</p>
        </div>
        <p className='body-text text-[#80828D]'>{time}</p>
      </div>
    );
  };

  const handleSaveEvent = ({ title, eventType, color }) => {
    if (title) {
      const newEvent = {
        title,
        start: dateInfo ? dateInfo.date : null,
        end: dateInfo ? dateInfo.date : null,
        extendedProps: { eventType },
        color,
      };
      setEvents([...events, newEvent]);
    }
    setIsModalOpen(false);
  };

  const handleTimeGridChange = (e) => {
    if (e) {
      setGrid(e.value);
      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.changeView(e.value);
      }
    }
  };
  const formatShortWeekday = (locale, date) => {
    const day = date.getDay();
    const dayNames = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
    return dayNames[day];
  };
  return (
    <div>
      <Navbar hemBurgerIcon={true} ProfileAndNotification={true} CompanyNavbar={true} />
      <div className='flex '>
        <div className='w-0 lg:w-[9%]'>
        <SideNavbar />
        </div>
        <div className='flex w-full lg:w-[91%]'>
          {activeTab && (
            <div className='hidden w-0 lg:block lg:w-[25%] p-4 bg-white'>
              
              <div>
              <Calendar onChange={onChange} value={value} formatShortWeekday={formatShortWeekday} />
              </div>

                  <div className='text-start mt-4'>
      <h6 className='h6-text font-semibold text-lg'>Important events</h6>
      <div>
        <p className='s3-text text-[#2C2C2C] my-2'>Today</p>
        {getEventsData?.Today?.length>0 ? getEventsData?.Today?.map((item) => (
            <EventItem
            color={item?.priority ==1 ? 'text-green-500': item?.priority ==2 ? 'text-yellow-500' : item?.priority ==3 ? "text-orange-500" : 'text-red-500'}
            title={item.title}
            time={formatTime(item.start_time)}
          />
        )):
        <h6>Event Not Available</h6>
        }
      
       
      </div>
      <div className='mt-4'>
        <p className='s3-text text-[#2C2C2C] my-2'>Tomorrow</p>
        {getEventsData?.Tomorrow?.length>0 ? getEventsData?.Tomorrow?.map((item) => (
            <EventItem
            color={item?.priority ==1 ? 'text-green-500': item?.priority ==2 ? 'text-yellow-500' : item?.priority ==3 ? "text-orange-500" : 'text-red-500'}
            title={item.title}
            time={formatTime(item.start_time)}
          />
        )):
        <h6>Event Not Available</h6>
      }
      </div>
    </div>
            </div>
          )}
          <div className='w-full '>
          
           <CompanyCanlenderComp setEventInfo={setEventInfo} setisEventViewModalOpen={setisEventViewModalOpen} onClick={() => setActiveTab(!activeTab)} handleAddEventClick={handleAddEventClick}/>
          </div>
        </div>

        

      </div>

      <div className='block w-full m-auto lg:hidden p-4 bg-white'>
              
              <div>
              <Calendar onChange={onChange} value={value} formatShortWeekday={formatShortWeekday} />
              </div>

                  <div className='text-start mt-4'>
      <h6 className='h6-text font-semibold text-lg'>Important events</h6>
      <div>
        <p className='s3-text text-[#2C2C2C] my-2'>Today</p>
        {getEventsData?.Today?.length>0 ? getEventsData?.Today?.map((item) => (
            <EventItem
            color={item?.priority ==1 ? 'text-green-500': item?.priority ==2 ? 'text-yellow-500' : item?.priority ==3 ? "text-orange-500" : 'text-red-500'}
            title={item.title}
            time={item.start_time}
          />
        )):
        <h6>Event Not Available</h6>
        }
      
       
      </div>
      <div className='mt-4'>
        <p className='s3-text text-[#2C2C2C] my-2'>Tomorrow</p>
        {getEventsData?.Tomorrow?.length>0 ? getEventsData?.Tomorrow?.map((item) => (
            <EventItem
            color={item?.priority ==1 ? 'text-green-500': item?.priority ==2 ? 'text-yellow-500' : item?.priority ==3 ? "text-orange-500" : 'text-red-500'}
            title={item.title}
            time={item.start_time}
          />
        )):
        <h6>Event Not Available</h6>
      }
      </div>
    </div>
            </div>

      <CompanyAddEvent
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCloseView={() => setisEventViewModalOpen(false)}
        type="addCalendarCompany"
        onSave={handleSaveEvent}
        eventInfo={eventInfo}
      />

<CompanyEventView
              isOpen={isEventViewModalOpen}
              onClose={() => setisEventViewModalOpen(false)}
              onSave={handleSaveEvent}
              setIsModalOpen={setIsModalOpen}
              eventInfo={eventInfo}
            />
    </div>
  );
};

export default CompanyCalendar;
