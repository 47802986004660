import React, { useEffect, useState } from "react";
import Button from "../../../../common/Button";
import {
  CrossIcon1,
  DiscriptionIcon,
  GroupIcon,
  IButtonIcon,
  JobListingCompPencil,
  LocationIcon,
  TimeIcon,
} from "../../../../common/Icons";
import InputField from "../../../../common/InputField";
import Checkbox from "../../../../common/Checkbox";
import SelectTagComponent from "../../../../common/SelectTagComponent";
import { ArrayOfTime, ValidationForMeeting, ValidationForMeetingSchedule, getFutureTimes } from "../../../../Hook/validation";
import usePost from "../../../../Hook/usePost";
import { ToastMessage } from "../../../../common/Config";
import { useNavigate } from "react-router-dom";
import Tooltip from "../calendar/Tooltip";

const ScheduledMeeting = ({ ScheduleBtn,data,className }) => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const navigate=useNavigate();
  const { data: meetingData,loading:meetingLoading, error: meetingError, postData: postMeetingData } = usePost(`hiring/company-schedule-meeting`);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [isInPerson, setIsInPerson] = useState(false);
  const [errors,setErrors]=useState({})
  const [formState, setFormState] = useState({
  title: "",
  job_hire: data?.job_hire_id || 1,
  is_virtual: true,
  preferred_date: "",
  email:"",
  preferred_start_time: "",
  preferred_end_time: "",
  preferred_date2: "",
  preferred_start_time2: "",
  preferred_end_time2: "",
  description: "",
  priority: 1,
  banker_user: data?.bkUser?.id ||1
});

  const handlePriorityClick = (priority) => {
    setFormState({...formState,priority:priority})
    setSelectedPriority(priority);
  };

  const toggleModal = () => {
    
    setIsOpen(!isOpen);
  };

  const handleCheckboxChange = (type) => {
    setIsInPerson(type === "in-person");
    setFormState({...formState,is_virtual:type==="virtual" ? true :false})
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if(errors[name])ValidationForMeetingSchedule(formState,setErrors)
  };
//  const handleSelectStartTimeChange=(val)=>{
// setFormState({...formState,preferred_start_time:val?.name})
// ValidationForMeeting({...formState,preferred_start_time:val?.name},setErrors)
//  }
//  const handleSelectEndTimeChange=(val)=>{
// setFormState({...formState,preferred_end_time:val?.name})
// ValidationForMeeting({...formState,preferred_end_time:val?.name},setErrors)
//  }

 const handleSelectStartTimeChange=(val)=>{
  setFormState({...formState,preferred_start_time:val})
  ValidationForMeetingSchedule({...formState,preferred_start_time:val},setErrors)
   }
   const handleSelectEndTimeChange=(val)=>{
  setFormState({...formState,preferred_end_time:val})
  ValidationForMeetingSchedule({...formState,preferred_end_time:val},setErrors)
   }
//  const handleSelectStartTimeChange2=(val)=>{
//    setFormState({...formState,preferred_start_time2:val?.name})
//    ValidationForMeeting({...formState,preferred_start_time2:val?.name},setErrors)
//  }
//  const handleSelectEndTimeChange2=(val)=>{
//    setFormState({...formState,preferred_end_time2:val?.name})
//    ValidationForMeeting({...formState,preferred_end_time2:val?.name},setErrors)
//  }

const handleSelectStartTimeChange2=(val)=>{
  console.log('sdasdfdfs',val)
  setFormState({...formState,preferred_start_time2:val})
  ValidationForMeetingSchedule({...formState,preferred_start_time2:val},setErrors)
}
const handleSelectEndTimeChange2=(val)=>{
  setFormState({...formState,preferred_end_time2:val})
  ValidationForMeetingSchedule({...formState,preferred_end_time2:val},setErrors)
}

  const handleSubmit=()=>{
    if(ValidationForMeetingSchedule(formState,setErrors)){
      postMeetingData(formState,token)

    }
  }
  useEffect(()=>{
    if(meetingData){
      ToastMessage(
        "success", 
        `Your meeting with ${formState?.is_virtual ? 'Mr./Ms.' : 'Mr./Ms.'} ${data?.bkUser?.name} has been scheduled on ${formState?.preferred_date} at ${formState?.preferred_start_time} hours and on ${formState?.preferred_date2} at ${formState?.preferred_start_time2} hours.`
      );
      
      setIsOpen(!isOpen);
      setTimeout(()=>{
        navigate('/dashboard/company')
      },2000)
    }
    if(meetingError){
      ToastMessage("error","Meeting Scheduled Failed")
    }
  },[meetingData,meetingError])
  return (
    <div>
      <Button
        onClick={toggleModal}
        // className="px-6 py-2 text-white greenbg rounded-lg"
        className={`px-6 py-2 text-white ${className?className:'greenbg'} rounded-lg`}
      >
        {ScheduleBtn}
      </Button>
      {isOpen && (
        <div className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-full bg-[#1E1F24] bg-opacity-50 backdrop-blur-sm">
          <div className="relative p-4 w-[90%]  lg:w-[43.5rem] max-h-[100%] bg-white rounded-lg shadow">
          <div className="absolute z-50 bg-white top-0 right-0 left-0 flex items-center justify-between p-4 md:p-3 border-b rounded-t">
              <h5 className="h5-text">Schedule a meeting</h5>
              <Button
                type="button"
                onClick={toggleModal}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
              >
                <CrossIcon1 />
              </Button>
            </div>

            <div className="overflow-auto h-[26rem] pt-10 mt-4 md:p-8 space-y-5 custom-scrollbar scrollbar-hide">
              <div className="flex items-center gap-6">
                <JobListingCompPencil />
                <InputField
                  astrix={false}
                  disabled={false}
                  required={true}
                  label="Add Title"
                  type="text"
                  id="addTitle"
                  name="title"
                  error={errors?.title}
                  value={formState.title}
                  onChange={handleInputChange}
                />
              </div>
              <div className="flex items-center gap-6">
                <GroupIcon />
                <InputField
                  astrix={false}
                  disabled={true}
                  required={true}
                  label="Sumit Mathur"
                  type="text"
                  id="name"
                  name="name"
                  value={data?.bkUser?.name}
                />
              </div>
              <div className="ml-12 lg:flex gap-[10rem]">
               <div>
               <Checkbox
                  type="radio"
                  id="virtual"
                  label="Virtual"
                  checked={!isInPerson}
                  onChange={() => handleCheckboxChange("virtual")}
                />
               </div>
               <div className="mt-3 lg:mt-0" >
               <Checkbox
                  type="radio"
                  id="in-person"
                  label="In-person"
                  checked={isInPerson}
                  onChange={() => handleCheckboxChange("in-person")}
                />
               </div>
              </div>
              {isInPerson && (
                <div className="flex items-center gap-6">
                  <LocationIcon />
                  <InputField
                    astrix={false}
                    disabled={false}
                    required={true}
                    label="Enter Address"
                    type="text"
                    id="address"
                    name="email"
                    error={errors?.email}
                    value={formState.email}
                    onChange={handleInputChange}
                  />
                </div>
              )}
              <div>
                <div>
                  <p className="body-text text-[#2C2C2C] pl-12 text-start">
                    Preferred time 1
                  </p>
                </div>
                <div className="grid w-full grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="flex items-center justify-start gap-6">
                    <div>
                      <TimeIcon />
                    </div>
                    <div className="w-full">
                      <InputField
                        name="preferred_date"
                        value={formState.preferred_date}
                        error={errors?.preferred_date}
                        type="date"
                        mindates={new Date().toISOString().split("T")[0]} 
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  {/* <div className="lg:flex w-full items-center gap-3 mt-2 justify-between">
                    <div className="w-full lg:pl-0 pl-12">
                      <SelectTagComponent
                        defaultVal="select time"
                        options={getFutureTimes(ArrayOfTime,formState?.preferred_date)}
                          name="preferred_start_time"
                          value={formState.preferred_start_time}
                          onChange={(val)=>handleSelectStartTimeChange(val)}
                          error={errors?.preferred_start_time}
                      />
                    </div>
                    <div className="my-2 lg:my-0">
                      <p>to</p>
                    </div>
                    <div className="w-full lg:pl-0 pl-12">
                      <SelectTagComponent
                       defaultVal="select time"
                            options={getFutureTimes(ArrayOfTime,formState?.preferred_date)}
                            name="preferred_end_time"
                            value={formState.preferred_end_time}
                            onChange={(val)=>handleSelectEndTimeChange(val)}
                            error={errors?.preferred_end_time}
                      />
                    </div>
                  </div> */}
                  
<div className="lg:flex w-full items-center gap-3 justify-between">
    <div className="w-full lg:pl-0 pl-12">
        <div className="relative">
            <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clip-rule="evenodd"/>
                </svg>
            </div>

            <InputField
            label='Start Time'
            name="preferred_start_time"
            value={formState.preferred_start_time}
            onChange={(e)=>handleSelectStartTimeChange(e.target.value)}
            error={errors?.preferred_start_time}
            type="time" id="start-time"
            className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
            min="09:00"
            max="18:00"
            required 
               />
        </div>
    </div>
    <div className="w-full lg:pl-0 pl-12">
        <div className="relative">
            <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clip-rule="evenodd"/>
                </svg>
            </div>
            <InputField
            label='End Time'
             type="time"
             name="preferred_end_time"
             value={formState.preferred_end_time}
             onChange={(e)=>handleSelectEndTimeChange(e.target.value)}
             error={errors?.preferred_end_time}
            id="end-time"
            className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             min="09:00"
             max="18:00"
            required />
        </div>
    </div>
</div>
                </div>


              </div>
              <div>
                <div>
                  <p className="body-text text-[#2C2C2C] pl-12 text-start">
                    Preferred time 2
                  </p>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="flex items-center justify-start gap-6">
                    <div>
                      <TimeIcon />
                    </div>
                    <div className="w-full">
                      <InputField
                        name="preferred_date2"
                        type="date"
                        value={formState.preferred_date2}
                        onChange={handleInputChange}
                        mindates={new Date().toISOString().split("T")[0]} 
                        error={errors?.preferred_date2}
                      />
                    </div>
                  </div>
                  {/* <div className="lg:flex items-center gap-3 mt-2 justify-between">
                    <div className="w-full lg:pl-0 pl-12">
                      <SelectTagComponent
                         defaultVal="select time"
                         options={getFutureTimes(ArrayOfTime,formState?.preferred_date2)}
                         name="preferred_start_time2"
                         value={formState.preferred_start_time2}
                         onChange={(val)=>handleSelectStartTimeChange2(val)}
                         error={errors?.preferred_start_time2}
                      />
                    </div>
                    <div className="my-2 lg:my-0">
                      <p>to</p>
                    </div>
                    <div className="w-full lg:pl-0 pl-12">
                      <SelectTagComponent            
                       defaultVal="select time"
                         options={getFutureTimes(ArrayOfTime,formState?.preferred_date2)}
                         name="preferred_end_time2"
                         value={formState.preferred_end_time2}
                         onChange={(val)=>handleSelectEndTimeChange2(val)}
                         error={errors?.preferred_end_time2}
                          />
                    </div>
                  </div> */}
                  <div className="lg:flex w-full items-center gap-3 justify-between">
    <div className="w-full lg:pl-0 pl-12">
        {/* <label for="start-time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Start time</label> */}
        <div className="relative">
            <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clip-rule="evenodd"/>
                </svg>
            </div>
            <InputField
            label='Start Time'
            name="preferred_start_time2"
            value={formState.preferred_start_time2}
            onChange={(e)=>handleSelectStartTimeChange2(e.target.value)}
            error={errors?.preferred_start_time2}
            type="time" id="start-time"
            className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
            min="09:00"
            max="18:00"
            required 
               />
        </div>
    </div>
    <div className="w-full lg:pl-0 pl-12">
        {/* <label for="end-time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">End time</label> */}
        <div className="relative">
            <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clip-rule="evenodd"/>
                </svg>
            </div>
            <InputField
            label='End Time'
             type="time"
             name="preferred_end_time2"
             value={formState.preferred_end_time2}
             onChange={(e)=>handleSelectEndTimeChange2(e.target.value)}
             error={errors?.preferred_end_time2}
            id="end-time"
            className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             min="09:00"
             max="18:00"
            required />
        </div>
    </div>
</div>
                </div>
              </div>
              <div className="flex !mt-[3rem] gap-6">
                <div className="">
                  <DiscriptionIcon />
                </div>
                <textarea
                  id="description"
                  name="description"
                  rows="4"
                  className="mt-1 resize-none block w-full rounded-md border-gray-300 border p-4"
                  placeholder="Add Description"
                  value={formState.description}
                  onChange={handleInputChange}
                  error={errors?.description}
                />
              </div>
              <p className="text-[#ff0000]">{errors?.description}</p>

              <p className="body-text text-[#2C2C2C] pl-12 text-start">
                Set Priority
              </p>
              <div className="flex items-start md:items-center gap-6">
              <Tooltip content="please select priority" >
              <IButtonIcon />

              </Tooltip>
                <div className=" grid lg:grid-cols-4 grid-cols-1 gap-4">
                  <Button
                    onClick={() => handlePriorityClick(1)}
                    className={`pl-3 pr-5 py-1 rounded-full bg-[#B6E4CB] flex items-center gap-3 ${
                      selectedPriority === 1 ? "border-2 border-black" : ""
                    }`}
                  >
                    <div
                      className={`w-[20px] h-[20px] rounded-full bg-[#0AA350] ${
                        selectedPriority !== "low" ? "opacity-50" : ""
                      }`}
                    ></div>
                    Low
                  </Button>
                  <Button
                    onClick={() => handlePriorityClick(2)}
                    className={`pl-3 pr-5 py-1 rounded-full bg-[#FFF6BE] flex items-center gap-3 ${
                      selectedPriority === 2
                        ? "border-2 border-black"
                        : ""
                    }`}
                  >
                    <div
                      className={`w-[20px] h-[20px] rounded-full bg-[#FDD33E] ${
                        selectedPriority !== 2 ? "opacity-50" : ""
                      }`}
                    ></div>
                    Medium
                  </Button>
                  <Button
                    onClick={() => handlePriorityClick(3)}
                    className={`pl-3 pr-5 py-1 rounded-full bg-[#FFD3B3] flex items-center gap-3 ${
                      selectedPriority === 3 ? "border-2 border-black" : ""
                    }`}
                  >
                    <div
                      className={`w-[20px] h-[20px] rounded-full bg-[#FF6B00] ${
                        selectedPriority !== 3 ? "opacity-50" : ""
                      }`}
                    ></div>
                    High
                  </Button>
                  <Button
                    onClick={() => handlePriorityClick(4)}
                    className={`pl-3 pr-5 py-1 rounded-full bg-[#FFC6C6] flex items-center gap-3 ${
                      selectedPriority === 4
                        ? "border-2 border-black"
                        : ""
                    }`}
                  >
                    <div
                      className={`w-[20px] h-[20px] rounded-full bg-[#FD3E3E] ${
                        selectedPriority !== 4 ? "opacity-50" : ""
                      }`}
                    ></div>
                    Critical
                  </Button>
                </div>
              </div>
              <div className="h-[3rem]"></div>
            </div>
            <div className="absolute bottom-0 z-50 left-0 right-0 bg-white flex items-center py-2 px-6 border-t border-gray-200 rounded-b">
              <Button
                onClick={handleSubmit}
                className="ml-auto greenbg w-[156px] rounded-lg h-[48px] px-2 py-2 text-white"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScheduledMeeting;
