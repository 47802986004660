import React, { useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CalendarIcon,
  CandidatesIcon,
  DashboardIcon,
  JobAvailableIcon,
  JobIcon,
  NotificationIcon,
  ProjectIcon,
  ReferralsIcon,
  SavedBankerIcon,
  SearchIcon,
  HamburgerIcon,
  CloseIcon,
} from "./Icons"; // Ensure these icons are imported correctly
import {
  SideNavbarOptionActive,
  SideNavbarOptionActiveMobile,
  SideNavbarStyleContainer,
  SideNavbarStyleContainerMobile,
  SideNavbarStyleDiv,
  SideNavbarStyleDivPtag,
  SideNavbarStyleDivPtagtwo,
  SideNavbarStyleDivPtagtwoGreen,
  SideNavbarStyleDivTwo,
  SideNavbarStyleMainContainer,
} from "./StyleCommon";
import useGetData from "../Hook/useGetData";
import { useSidebar } from "./SidebarProvider";

const SideNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isSidebarOpen, toggleSidebar } = useSidebar();

  const token = JSON.parse(sessionStorage.getItem('userToken')) || "";
  const { data, loading, error, refetch } = useGetData('job/list', token);

  const jobsAvailable = useCallback(() => {
    return Array.isArray(data?.data) && data?.data.length > 0;
  }, [data]);


  return (
    <>
    

      {isSidebarOpen && (
        // <div className="fixed inset-0 bg-gray-800 bg-opacity-75 z-50">
        <div className="lg:hidden fixed w-full h-full top-0 left-0 right-0 bg-white z-50 flex flex-col justify-start">
          <div onClick={toggleSidebar} className=" w-full py-4 px-4 flex justify-between cursor-pointer">
            <div>
                <h6 className="h6-text text-[#2C2C2C]">
                  Menu
                </h6>
            </div>
            <div>
            <CloseIcon />
            </div>
          </div>
        
          <div className="flex flex-col items-center bg-white h-full overflow-y-auto">
            {/* Sidebar content here */}

            <div
              
              className={`${location?.pathname === '/dashboard/company' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}
               
              onClick={() => {
                navigate("/dashboard/company");
                toggleSidebar();
              }}
            >
              <div
                className='rounded-full flex items-center justify-center'
              >
                <DashboardIcon location={location} />
              </div>
              <div className="">
                <p className={`${location?.pathname === '/dashboard/company' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}>Dashboard</p>
              </div>
            </div>

            



            <div
              onClick={() => {
                navigate("/dashboard/company/search");
                toggleSidebar();
              }}
              className={`${location?.pathname === '/dashboard/company/search' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}
              
            >
              <div  className='rounded-full flex items-center justify-center'>
                <SearchIcon location={location}/>
              </div>
              <div>
                <p className={`${location?.pathname === '/dashboard/company/search' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}>Search Bankers</p>
              </div>
            </div>





            <div
              className={`${location?.pathname === '/dashboard/savedbankers' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}
              
              onClick={() => {
                navigate("/dashboard/savedbankers");
                toggleSidebar();
              }}
            >
              <div
                className='rounded-full flex items-center justify-center'
              >
                <SavedBankerIcon location={location} />
              </div>
              <div>
                <p className={`${location?.pathname === '/dashboard/savedbankers' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}>Saved Bankers</p>
              </div>
            </div>
            <div
              className={`${location?.pathname === '/dashboard/company/calendar' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}
              
              onClick={() => {
                navigate("/dashboard/company/calendar");
                toggleSidebar();
              }}
            >
              <div
                className='rounded-full flex items-center justify-center'
              >
                <CalendarIcon location={location} />
              </div>
              <div>
                <p className={`${location?.pathname === '/dashboard/company/calendar' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}>Calendar</p>
              </div>
            </div>
            <div
              className={`${location?.pathname === '/dashboard/joblisting' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}
              
              onClick={() => {
                navigate("/dashboard/joblisting");
                toggleSidebar();
              }}
            >
              <div
                className='rounded-full flex items-center justify-center'
              >
                {jobsAvailable() ? <JobAvailableIcon location={location} /> : <JobIcon location={location} />}
              </div>
              <div>
                <p className={`${location?.pathname === '/dashboard/joblisting' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}>Jobs</p>
              </div>
            </div>
            <div
              className={`${location?.pathname === '/dashboard/company/project' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}
              
              onClick={() => {
                navigate("/dashboard/company/project");
                toggleSidebar();
              }}
            >
              <div
                className='rounded-full flex items-center justify-center'
              >
                <ProjectIcon location={location} />
              </div>
              <div>
                <p className={`${location?.pathname === '/dashboard/company/project' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}>Projects</p>
              </div>
            </div>
            {/* <div
              className={`${location?.pathname === '/dashboard/company/referrals' ? SideNavbarOptionActiveMobile : SideNavbarStyleContainerMobile} `}
              
              onClick={() => {
                navigate("/dashboard/company/referrals");
                toggleSidebar();
              }}
            >
              <div
                className='rounded-full flex items-center justify-center'
              >
                <ReferralsIcon location={location} />
              </div>
              <div>
                <p className={`${location?.pathname === '/dashboard/company/referrals' ? SideNavbarStyleDivPtagtwoGreen : SideNavbarStyleDivPtagtwo} `}>Referrals</p>
              </div>
            </div> */}
          </div>
        </div>
      )}

      <div className="hidden custom-scrollbar fixed lg:flex flex-col items-center overflow-auto h-[91vh] border justify-start px-4 shadow-lg lg:w-[7%] md:w-[7%] bg-white z-50">
        {/* Sidebar content for desktop */}
        <div
          className={SideNavbarStyleContainer}
          onClick={() => navigate("/dashboard/company")}
        >
          <div
            className={
              location?.pathname === "/dashboard/company"
                ? `${SideNavbarStyleDiv} ${SideNavbarOptionActive}`
                : `${SideNavbarStyleDiv}`
            }
          >
            <DashboardIcon location={location} />
          </div>
          <div className="m-auto">
            <p className={SideNavbarStyleDivPtag}>Dashboard</p>
          </div>
        </div>
        <div
          onClick={() => navigate("/dashboard/company/search")}
          className={SideNavbarStyleContainer}
        >
          <div className={SideNavbarStyleDivTwo}>
            <SearchIcon />
          </div>
          <div>
            <p className={SideNavbarStyleDivPtagtwo}>Search Bankers</p>
          </div>
        </div>
        <div
          className={SideNavbarStyleContainer}
          onClick={() => navigate("/dashboard/savedbankers")}
        >
          <div
            className={
              location?.pathname === "/dashboard/savedbankers"
                ? `${SideNavbarStyleDivTwo} ${SideNavbarOptionActive}`
                : `${SideNavbarStyleDivTwo}`
            }
          >
            <SavedBankerIcon location={location} />
          </div>
          <div>
            <p className={SideNavbarStyleDivPtagtwo}>Saved Bankers</p>
          </div>
        </div>
        <div
          className={SideNavbarStyleContainer}
          onClick={() => navigate("/dashboard/company/calendar")}
        >
          <div
            className={
              location?.pathname === "/dashboard/company/calendar"
                ? `${SideNavbarStyleDivTwo} ${SideNavbarOptionActive}`
                : `${SideNavbarStyleDivTwo}`
            }
          >
            <CalendarIcon location={location} />
          </div>
          <div>
            <p className={SideNavbarStyleDivPtagtwo}>Calendar</p>
          </div>
        </div>
        <div
          className={SideNavbarStyleContainer}
          onClick={() => navigate("/dashboard/joblisting")}
        >
          <div
            className={
              location?.pathname === "/dashboard/joblisting"
                ? `${SideNavbarStyleDivTwo} ${SideNavbarOptionActive}`
                : `${SideNavbarStyleDivTwo}`
            }
          >
            {jobsAvailable() ? <JobAvailableIcon location={location} /> : <JobIcon location={location} />}
          </div>
          <div>
            <p className={SideNavbarStyleDivPtagtwo}>Jobs</p>
          </div>
        </div>
        <div
          className={SideNavbarStyleContainer}
          onClick={() => navigate("/dashboard/company/project")}
        >
          <div
            className={
              location?.pathname === "/dashboard/company/project"
                ? `${SideNavbarStyleDivTwo} ${SideNavbarOptionActive}`
                : `${SideNavbarStyleDivTwo}`
            }
          >
            <ProjectIcon location={location} />
          </div>
          <div>
            <p className={SideNavbarStyleDivPtagtwo}>Projects</p>
          </div>
        </div>
        {/* <div
          className={SideNavbarStyleContainer}
          onClick={() => navigate("/dashboard/company/referrals")}
        >
          <div
            className={
              location?.pathname === "/dashboard/company/referrals"
                ? `${SideNavbarStyleDivTwo} ${SideNavbarOptionActive}`
                : `${SideNavbarStyleDivTwo}`
            }
          >
            <ReferralsIcon location={location} />
          </div>
          <div>
            <p className={SideNavbarStyleDivPtagtwo}>Referrals</p>
          </div>
        </div> */}
        <div className="w-full h-[35rem]">


        </div>
      </div>
    </>
  );
};

export default SideNavbar;
