import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import usePost from '../../../../Hook/usePost';
import { ArrayOfTime, ValidationForMeeting, ValidationForMeetingBanker, formatTime, getFutureTimes } from '../../../../Hook/validation';
import { ToastMessage } from '../../../../common/Config';
import Button from '../../../../common/Button';
import { AddClientIcon, CrossIcon1, DeleteGreenButton, DeleteIcon, DiscriptionIcon, DownloadGreemButton, GroupIcon, IButtonIcon, JobListingCompPencil, LinkIcon, LoctionIcon1, TimeIcon } from '../../../../common/Icons';
import InputField from '../../../../common/InputField';
import Checkbox from '../../../../common/Checkbox';
import SelectTagComponent from '../../../../common/SelectTagComponent';
import useGetData from '../../../../Hook/useGetData';
import Tooltip from '../../company/calendar/Tooltip';
import { BankerAddEventStyles1, BankerAddEventStyles10, BankerAddEventStyles11, BankerAddEventStyles12, BankerAddEventStyles13, BankerAddEventStyles14, BankerAddEventStyles15, BankerAddEventStyles16, BankerAddEventStyles17, BankerAddEventStyles18, BankerAddEventStyles19, BankerAddEventStyles1m, BankerAddEventStyles2, BankerAddEventStyles20, BankerAddEventStyles21, BankerAddEventStyles22, BankerAddEventStyles23, BankerAddEventStyles24, BankerAddEventStyles25, BankerAddEventStyles26, BankerAddEventStyles27, BankerAddEventStyles28, BankerAddEventStyles29, BankerAddEventStyles3, BankerAddEventStyles30, BankerAddEventStyles31, BankerAddEventStyles32, BankerAddEventStyles33, BankerAddEventStyles34, BankerAddEventStyles35, BankerAddEventStyles36, BankerAddEventStyles37, BankerAddEventStyles38, BankerAddEventStyles39, BankerAddEventStyles4, BankerAddEventStyles40, BankerAddEventStyles41, BankerAddEventStyles42, BankerAddEventStyles43, BankerAddEventStyles44, BankerAddEventStyles5, BankerAddEventStyles6, BankerAddEventStyles7, BankerAddEventStyles8, BankerAddEventStyles9 } from './CalendarStyles';

const BankerAddEvent = ({ isOpen, onClose, onSave,type,eventInfo,banker,company,hideDropDown }) => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const { data: CompanyEventData,loading:CompanyEventLoading, 
    error: CompanyEventError, postData: postCompanyEventData } = usePost(`hiring/event-banker`);
    const { data: CompanyEventUpdateData,loading:EventUpdateLoading, 
      error: CompanyEventUpdateError, postData: postCompanyEventUpdateData } = usePost(`hiring/update-event-banker`);
      const { data: EventDeleteData,loading:EventDeleteLoading, 
        error: EventDeleteError, postData: postEventDeleteData } = usePost(`hiring/delete-event`);
    const { data: getBankerList } = useGetData(`hiring/get-company`,token);
    const [formState, setFormState] = useState({
    title: '',
    is_virtual: true,
    preferred_date: '',
    banker:banker || '',
    email: '',
    preferred_start_time: '',
    preferred_end_time: '',
    description: '',
    priority: 1,
  });
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [isInPerson, setIsInPerson] = useState(false);
  const [isRepeat, setIsRepeat] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [errors, setErrors] = useState({});

  const days = [
    { label: 'M', name: 'Monday' },
    { label: 'T', name: 'Tuesday' },
    { label: 'W', name: 'Wednesday' },
    { label: 'T', name: 'Thursday' },
    { label: 'F', name: 'Friday' },
    { label: 'S', name: 'Saturday' },
    { label: 'S', name: 'Sunday' },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (errors[name]) ValidationForMeetingBanker(formState, setErrors);
  };

  const handleSelectChange = (name, val) => {
    if(name=='banker_user'){
      setFormState((prevState) => ({
        ...prevState,
        [name]: val.id,
      }));
    }else{
      setFormState((prevState) => ({
        ...prevState,
        [name]: val.name,
      }));
    }
   
    ValidationForMeetingBanker({ ...formState, [name]: val.name }, setErrors);
  };

  const handleCheckboxChange = (type) => {
    setIsInPerson(type === 'in-person');
    setFormState((prevState) => ({
      ...prevState,
      is_virtual: type === 'virtual',
    }));
  };

  const handleClick = (dayName) => {
    setSelectedDays((prev) =>
      prev.includes(dayName)
        ? prev.filter((d) => d !== dayName)
        : [...prev, dayName]
    );
    setFormState((prevState) => ({ ...prevState, repeat_unit: selectedDays }));
  };

  const handlePriorityClick = (priority) => {
    setSelectedPriority(priority);
    setFormState((prevState) => ({
      ...prevState,
      priority,
    }));
  };

  const handleSubmit = () => {

    if (ValidationForMeetingBanker(formState, setErrors)) {
      if(type=='bankerProjectDetail'){
        const data = {
          "company_id": formState?.banker_user || 1,
          "title": formState?.title || "",
          "consultancy_mode": formState?.is_virtual ? 1 :2,
          "location": formState?.email,
          "event_date":formState?.preferred_date,
          "start_time":formState?.preferred_start_time,
          "end_time": formState?.preferred_end_time=="24:00" ? "00:00" :formState?.preferred_end_time,
          "description":formState?.description,
          "priority":formState?.priority,
      
          "is_repeat": isRepeat || false,
          "repeat_interval":parseInt(formState?.repeatEvery),
          "repeat_start_date":formState?.startRepeatDate,
          "repeat_end_date":formState?.endRepeatDate,
          "repeat_unit":formState?.repeate_unit?.toLowerCase(),
          "weekly_repeat_days":selectedDays ||formState?.repeat_unit || [] ,
          unit:formState?.repeatType?.toLowerCase(),
      
      
      
      }
      postCompanyEventData(data,token)
      }
      if(type=='addCalendarBanker' && formState?.edit !==true){
        const data = {
          "company_id": formState?.banker_user || 1,
          "title": formState?.title || "",
          "consultancy_mode": formState?.is_virtual ? 1 :2,
          "location": formState?.email,
          "event_date":formState?.preferred_date,
          "start_time":formState?.preferred_start_time,
          "end_time": formState?.preferred_end_time=="24:00" ? "00:00" :formState?.preferred_end_time,
          "description":formState?.description,
          "priority":formState?.priority,
      
          "is_repeat": isRepeat || false,
          "repeat_interval":parseInt(formState?.repeatEvery),
          "repeat_start_date":formState?.startRepeatDate,
          "repeat_end_date":formState?.endRepeatDate,
          "repeat_unit":formState?.repeate_unit?.toLowerCase(),
          "weekly_repeat_days":selectedDays ||formState?.repeat_unit || [] ,
          unit:formState?.repeatType?.toLowerCase(),
      
      
      
      }
     
      postCompanyEventData(data,token)
      }
      if(formState?.edit===true){
        const data = {
          "event_id": formState?.id,
          "banker": formState?.banker || 1,
          "company":formState?.banker_user || 1,
          "title": formState?.title || "",
          "consultancy_mode": formState?.is_virtual ? 1 :2,
          "location": formState?.email,
          "event_date":formState?.preferred_date,
          "start_time":formState?.preferred_start_time,
          "end_time": formState?.preferred_end_time=="24:00" ? "00:00" :formState?.preferred_end_time,
          "description":formState?.description,
          "priority":formState?.priority,
          meeting_link:formState?.link,
          link:formState?.link,
          "is_repeat": isRepeat || false,
          "repeat_interval":parseInt(formState?.repeatEvery || formState?.repeat_interval),
          "repeat_start_date":formState?.startRepeatDate || formState?.repeat_start_date,
          "repeat_end_date":formState?.endRepeatDate || formState?.repeat_end_date,
          "repeat_unit":formState?.repeate_unit?.toLowerCase() || formState?.repeat_unit,
          "weekly_repeat_days":selectedDays ||formState?.repeat_unit || [] ,
          unit:formState?.repeatType?.toLowerCase(),
      }
      postCompanyEventUpdateData(data,token) 
      }
      // ToastMessage('success', 'Meeting Scheduled Successfully');
      // onSave(formState);
      // onClose();
    }
  };
  const RepeatList=[{ id: 1, name: 'Day' }, { id: 2, name: 'Week' }, { id: 3, name: 'Month' }, { id: 4, name: 'Year' }]
useEffect(()=>{
  if(CompanyEventData){
    ToastMessage('success', 'Meeting Scheduled Successfully');
    onClose()
  }
  if(CompanyEventError){
    ToastMessage('error', 'Meeting Scheduled Failed');
  }
},[CompanyEventData,CompanyEventError])
useEffect(()=>{

  const obj={
    ...eventInfo,
    email:eventInfo?.location,
    consultancy_mode:eventInfo?.consultancy_mode,
    preferred_date:eventInfo?.event_date,
    preferred_start_time:eventInfo?.start_time,
    preferred_end_time:eventInfo?.end_time,
  }
  setIsRepeat(eventInfo?.is_repeat)
  setIsInPerson(eventInfo?.consultancy_mode==2?true:false)
  setSelectedDays(eventInfo?.weekly_repeat_days || [])
  setSelectedPriority(eventInfo?.priority)
  if(eventInfo?.id !==0 && !eventInfo?.edit){
    setFormState(obj)

  }
  if(eventInfo && eventInfo?.edit==true){
  
    setFormState({...eventInfo,is_virtual:eventInfo?.consultancy_mode==1 ? true:false,banker_user:eventInfo?.company,preferred_date:eventInfo?.event_date,preferred_start_time:formatTime(eventInfo?.start_time),preferred_end_time:formatTime(eventInfo?.end_time),link:eventInfo?.meeting_link})
  }
},[eventInfo])
useEffect(()=>{
  if(type=='companyProjectDetail'){
    setFormState((prevState) => ({ ...prevState, banker: banker}));
  }
},[banker])
useEffect(()=>{
  if(CompanyEventUpdateData){
    ToastMessage('success', 'Meeting Scheduled Successfully');
    onClose()
  }
  if(CompanyEventUpdateError){
    ToastMessage('error', 'Meeting Scheduled Failed');
  }
},[CompanyEventUpdateData,CompanyEventUpdateError])
useEffect(()=>{
  if(EventDeleteData){
    ToastMessage('success', 'Meeting deletion failed.');
    onClose()
  }
  if(EventDeleteError){
    ToastMessage('error', 'Meeting deletion failed');
  }
},[EventDeleteData,EventDeleteError])
useEffect(()=>{
  if(company){
    setFormState((prevState) => ({ ...prevState, banker_user: company }));
  }
},[company])
  if (!isOpen) return null;

  return (
    
    <div className={BankerAddEventStyles1}>
    <div className={BankerAddEventStyles1m}>
      <div className={BankerAddEventStyles2}>
        <h5 className={BankerAddEventStyles3}>Schedule a meeting</h5>
            <Button
              type="button"
              onClick={onClose}
              // onClick={toggleModal}
              className={BankerAddEventStyles4}
            >
              <CrossIcon1 />
            </Button>
          </div>
          <div className={BankerAddEventStyles5}>
            <div className={BankerAddEventStyles6}>
              <JobListingCompPencil />
              <InputField
                astrix={false}
                disabled={false}
                required={true}
                label="Add Title"
                type="text"
                id="addTitle"
                name="title"
                error={errors?.title}
                value={formState.title}
                onChange={handleInputChange}
              />
            </div>
            <div className={BankerAddEventStyles7}>
              <AddClientIcon />
           <SelectTagComponent
           
        defaultVal={(formState?.banker || type==='bankerProjectDetail') ? getBankerList?.data?.filter((item)=>item?.id===formState?.banker_user)[0]?.name : "Select Company"}
        options={getBankerList?.data}
        disabled={type=='companyProjectDetail' || type=='bankerProjectDetail'}
        name="banker_user"
        // value={formData.selectMonth}
        onChange={(val)=>handleSelectChange('banker_user',val)}
        error={errors.banker_user}
        hideDropDown={hideDropDown ? true : false}
      />
            </div>
            <div className={BankerAddEventStyles8}>
              <Checkbox
                type="radio"
                id="virtual"
                label="Virtual"
                checked={!isInPerson}
                onChange={() => handleCheckboxChange("virtual")}
              />

              <div className={BankerAddEventStyles9}>
              
              <Checkbox
                type="radio"
                id="in-person"
                label="In-person"
                checked={isInPerson}
                onChange={() => handleCheckboxChange("in-person")}
              />
              </div>
             
            </div>
            {isInPerson && (
              <div className={BankerAddEventStyles10}>
                <LoctionIcon1 />
                <InputField
                  astrix={false}
                  disabled={false}
                  required={true}
                  label="Add Location"
                  type="text"
                  id="address"
                  name="email"
                  error={errors?.email}
                  value={formState.email}
                  onChange={handleInputChange}
                />
              </div>
            )}

{(!isInPerson && eventInfo?.edit==true) && (
              <div className={BankerAddEventStyles10}>
                <LinkIcon />
                <InputField
                  astrix={false}
                  disabled={false}
                  required={true}
                  label="Add Link"
                  type="text"
                  id="link"
                  name="link"
                  error={errors?.link}
                  value={formState.link}
                  onChange={handleInputChange}
                />
              </div>
            )}
            <div>
             
            <div className={BankerAddEventStyles11}>
            <div className={BankerAddEventStyles12}>
                  <div>
                    <TimeIcon />
                  </div>
                  <div className='w-full'>
                    <InputField
                      name="preferred_date"
                      value={formState.preferred_date}
                      error={errors?.preferred_date}
                      type="date"
                      mindates={new Date().toISOString().split("T")[0]} 
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                {/* <div className={BankerAddEventStyles13}>
                <div className={BankerAddEventStyles14}>
                  <SelectTagComponent
                    defaultVal={formState?.start_time || "select time"}
                    options={getFutureTimes(ArrayOfTime, formState.preferred_date)}
                    name="preferred_start_time"
                    value={formState.preferred_start_time}
                    onChange={(val) =>
                      handleSelectChange('preferred_start_time', val)
                    }
                    error={errors?.preferred_start_time}
                  />
                </div>
                <div className={BankerAddEventStyles15}>
                    <p>to</p>
                  </div>
                  <div className={BankerAddEventStyles16}>
                  <SelectTagComponent
                    defaultVal={formState?.end_time || "select time"}
                    options={getFutureTimes(ArrayOfTime, formState.preferred_date)}
                    name="preferred_end_time"
                    value={formState.preferred_end_time}
                    onChange={(val) =>
                      handleSelectChange('preferred_end_time', val)
                    }
                    error={errors?.preferred_end_time}
                  />
                  </div>
                </div> */}

<div className="lg:flex w-full items-center gap-3 justify-between">
    <div className="w-full lg:pl-0 pl-12">
        {/* <label for="start-time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Start time</label> */}
        <div className="relative">
            <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clip-rule="evenodd"/>
                </svg>
            </div>
            <InputField
            label='Start Time'
            name="preferred_start_time"
            value={formState.preferred_start_time}
            onChange={handleInputChange}
            error={errors?.preferred_start_time}
            type="time" id="start-time"
            className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
            min="09:00"
            max="18:00"
            required 
               />
        </div>
    </div>
    <div className="w-full lg:pl-0 pl-12">
        {/* <label for="end-time" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">End time</label> */}
        <div className="relative">
            <div className="absolute inset-y-0 end-0 top-0 flex items-center pe-3.5 pointer-events-none">
                <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm11-4a1 1 0 1 0-2 0v4a1 1 0 0 0 .293.707l3 3a1 1 0 0 0 1.414-1.414L13 11.586V8Z" clip-rule="evenodd"/>
                </svg>
            </div>
            <InputField
            label='End Time'
             type="time"
             name="preferred_end_time"
             value={formState.preferred_end_time}
             onChange={handleInputChange}
             error={errors?.preferred_end_time}
            id="end-time"
            className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
             min="09:00"
             max="18:00"
            required />
        </div>
    </div>
</div>
              </div>
            </div>
<div className='pl-12'>
<Checkbox
              label="Repeat"
              type="checkbox"
              id="isRepeat"
              checked={isRepeat}
              onChange={() => setIsRepeat(!isRepeat)}
            />


{ isRepeat && <div>
  <div className={BankerAddEventStyles17}>
    <div className={BankerAddEventStyles18}>
      <p className='s3-text'>Start</p>
    </div>
    <div className={BankerAddEventStyles19}>
             <InputField
                      name="startRepeatDate"
                      value={formState?.startRepeatDate || formState.repeat_start_date}
                      // error={errors?.preferred_date}
                      mindates={new Date().toISOString().split("T")[0]} 
                      type="date"
                      onChange={handleInputChange}
                    />
    </div>
  </div>

  <div className={BankerAddEventStyles20}>
  <div className={BankerAddEventStyles21}>
      <p className='s3-text'>Repeat every</p>
    </div>
    <div className={BankerAddEventStyles22}>
             <div>
             <InputField
                      name="repeatEvery"
                      value={formState.repeat_interval}
                      // error={errors?.preferred_date}
                      type="text"
                      onChange={handleInputChange}
                    />
             </div>

             <div className={BankerAddEventStyles23}>
             <SelectTagComponent
               defaultVal={formState?.repeat_unit || "Select Unit"}
               options={RepeatList}
               onChange={(val) =>
                handleSelectChange('repeate_unit', val)
              }
              />
             </div>
    </div>
  </div>

  <div className={BankerAddEventStyles24}>
      {days.map((day, index) => (
        <div
          key={index}
          onClick={() => handleClick(day.name)}
          className={`${BankerAddEventStyles25} ${
            selectedDays.includes(day.name) ? 'bg-green-500 text-white' : 'bg-white text-black'
          }`}
        >
          {day.label}
        </div>
      ))}
    </div>

  <div className={BankerAddEventStyles26}>
    <div className={BankerAddEventStyles27}>
      <p className='s3-text'>End</p>
    </div>
    <div className={BankerAddEventStyles28}>
             <InputField
                      name="endRepeatDate"
                      value={formState?.endRepeatDate || formState.repeat_end_date}
                      // error={errors?.preferred_date}
                      mindates={new Date().toISOString().split("T")[0]} 
                      type="date"
                      onChange={handleInputChange}
                    />
    </div>
  </div>

  <p className={BankerAddEventStyles29}>
  Occurs every {selectedDays?.map((item)=>{
    return `${item}, `
  })}
  </p>
</div> }
</div>           
            <div className={BankerAddEventStyles30}>
              <div className="mt-2">
                <DiscriptionIcon />
              </div>
              <textarea
                id="description"
                name="description"
                rows="4"
                className={BankerAddEventStyles31}
                placeholder="Add Description"
                value={formState.description}
                onChange={handleInputChange}
                error={errors?.description}
              />
            </div>
            <p className="text-[#ff0000]">{errors?.description}</p>

            
            <div className={BankerAddEventStyles32}>
            <Tooltip content="please select priority" >
              <IButtonIcon />

              </Tooltip>
              <div className={BankerAddEventStyles33}>
                <Button
                  onClick={() => handlePriorityClick(1)}
                  className={`${BankerAddEventStyles34} ${
                    selectedPriority === 1 ? "border-2 border-black" : ""
                  }`}
                >
                  <div
                    className={`${BankerAddEventStyles35} ${
                      selectedPriority !== "low" ? "opacity-50" : ""
                    }`}
                  ></div>
                  Low
                </Button>
                <Button
                  onClick={() => handlePriorityClick(2)}
                  className={`${BankerAddEventStyles36} ${
                    selectedPriority === 2
                      ? "border-2 border-black"
                      : ""
                  }`}
                >
                  <div
                    className={`${BankerAddEventStyles37} ${
                      selectedPriority !== 2 ? "opacity-50" : ""
                    }`}
                  ></div>
                  Medium
                </Button>
                <Button
                  onClick={() => handlePriorityClick(3)}
                  className={`${BankerAddEventStyles38} ${
                    selectedPriority === 3 ? "border-2 border-black" : ""
                  }`}
                >
                  <div
                    className={`${BankerAddEventStyles39} ${
                      selectedPriority !== 3 ? "opacity-50" : ""
                    }`}
                  ></div>
                  High
                </Button>
                <Button
                  onClick={() => handlePriorityClick(4)}
                  className={`${BankerAddEventStyles40} ${
                    selectedPriority === 4
                      ? "border-2 border-black"
                      : ""
                  }`}
                >
                  <div
                    className={`${BankerAddEventStyles41} ${
                      selectedPriority !== 4 ? "opacity-50" : ""
                    }`} 
                  ></div>
                  Critical
                </Button>
              </div>
            </div>
            <div className="h-[3rem]"></div>
          </div>
          <div className={BankerAddEventStyles42}>
         {formState?.edit===true ? <Button
              onClick={()=>postEventDeleteData({"event_id":formState?.id},token)}
              className={BankerAddEventStyles43}
            >
              <DeleteGreenButton/>
              Delete
            </Button> :<div></div>}
            <Button
              onClick={handleSubmit}
              className={BankerAddEventStyles44}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
  
  );
};

export default BankerAddEvent;
