import { CrossIcon1 } from '../../../../common/Icons';
import Button from '../../../../common/Button';
import { CP_AM_MainDiv, CP_AM_MainDiv1, CP_AM_MainDiv2, CP_AM_MainDiv4H4, CP_AM_MainDiv5, CP_AM_MainDiv6, CP_AM_MainDiv7, CP_AM_MainDiv8 } from './CompanyProjectStyle';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ToastMessage } from '../../../../common/Config';
import usePost from '../../../../Hook/usePost';

const ApproveMilestoneModel = ({dataSet,setMessage,setShowNotification, isOpen, onClose, milestone_id,type,fetchData }) => {
  const token=JSON.parse(sessionStorage.getItem("userToken")) || "";
  const {id}=useParams()
  const { data: approveData,loading:approveLoading, error: approveError, postData: postApproveData } = usePost(`projects/company-milestone-approve/${id}`);
  const { data: declineData,loading:declineLoading, error: declineError, postData: postDeclineData } = usePost(`projects/company-milestone-deny/${id}`);
const [text,setText]=useState('')
const [error,setErrors]=useState("")
const handleSubmit=()=>{
  if(text){
    setErrors('')
    const obj={
      milestone_id:milestone_id,
      comment:text,
    }
    if(type=="approve" || type==='approved'){
    
      postApproveData(obj,token)
      //approve
    }
    if(type=="declined"){
      postDeclineData(obj,token)
    }
  }else{
    setErrors("Please enter your feedback")
  }

}
console.log("TTTTTTTTTT",dataSet,dataSet?.name);
useEffect(()=>{
  if(approveData){
    // ToastMessage("success","Milestone Approved Successfully")
    fetchData()
    onClose()
    setMessage(`You have approved the ${dataSet?.name || dataSet?.title} Successfully`)
    setShowNotification(true)
    setTimeout(() =>{
      setShowNotification(false)
    },7000)
  }
  if(approveError){
    ToastMessage("error","Something went wrong")
 
  }
},[approveData,approveError])
console.log("TRTRTRTRTRT",dataSet);
useEffect(()=>{
  if(declineData){
    // ToastMessage("success","Milestone Declined Successfully")
    fetchData()
    onClose()
    setMessage(`You have declined the ${dataSet?.name || dataSet?.title} Successfully`)
    setShowNotification(true)
    setTimeout(() =>{
      setShowNotification(false)
    },7000)
  }
  if(declineError){
    ToastMessage("error","Something went wrong")
  }
  },[declineData,declineError])
  if (!isOpen) return null;

  return (
    <div className={CP_AM_MainDiv}>
      <div className={CP_AM_MainDiv1}>
        <div className={CP_AM_MainDiv2}>
          <h4 className={CP_AM_MainDiv4H4}>Add your comment and experience</h4>
          
          <button onClick={onClose} className="text-gray-500">
            <CrossIcon1 />
          </button>
        </div>
       
        <div className={CP_AM_MainDiv5}>
          
          <textarea className={CP_AM_MainDiv6} placeholder='If you have any feedback, please type it in here' name="" id="" onChange={(e)=>setText(e.target.value)}></textarea>

        </div>
       <p className='text-[red]'>{error && error}</p>
        <div className={CP_AM_MainDiv7}>
          {/* <Button
            onClick={onClose}
            className="px-10 py-2 redfolicbg1 rounded-lg mr-2"
          >
            Cancel
          </Button> */}
          <Button className={CP_AM_MainDiv8} onClick={handleSubmit}>
          Submit Feedback
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApproveMilestoneModel;
